import React, { useState } from 'react';

const Converter = () => {
  const [inputValue, setInputValue] = useState('');
  const [resultValue, setResultValue] = useState('');
  const [unit, setUnit] = useState('ether');
  const [convertValue, setConvertValue] = useState('Enter wei value');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleUnitChange = () => {
    if (unit === 'ether') {
      setUnit('wei');
      setConvertValue('Enter ether value');
      setResultValue('')
    } else {
      setUnit('ether');
      setConvertValue('Enter wei value');
      setResultValue('')
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (unit === 'ether') {
        setResultValue((inputValue / 1000000000000000000).toString());
      } else {
        setResultValue((inputValue * 1000000000000000000).toString());
      }
  }

  return (
    <div>
        <h1 className='flex justify-center items-center mb-2 mt-10 mb-5 text-xl'>Ether-Wei Converter</h1>
        <form onSubmit={handleSubmit}  className='lg:mb-0 mx-10 lg:flex items-center justify-center'>
        <div className='flex items-center justify-center'>
            <input type="text" value={inputValue} placeholder={convertValue} onChange={handleInputChange} className='mr-5 lg:mr-8 w-55 lg:w-60 pl-2 pr-2 items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'/>
            <button className='lg:mt-0 items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white' type="submit">
            <span className="px-5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
                Submit
            </span>
            </button>
        </div>
        </form>
        <div className='flex justify-center items-center'>
            <button onClick={handleUnitChange} className="flex">
            <div className='w-10 mr-1'>
                {unit === 'ether' ? 'Wei' : 'Ether'}
            </div>
            <div>
                <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="#000000"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M6 19L3 16M3 16L6 13M3 16H11C12.6569 16 14 14.6569 14 13V12M10 12V11C10 9.34315 11.3431 8 13 8H21M21 8L18 11M21 8L18 5" stroke="#9333ea" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </g></svg> 
            </div>
            <div className='w-8 ml-2'>
                {unit === 'ether' ? 'Ether' : 'Wei'}
            </div>
            </button>
        </div>
        <div className='flex justify-center text-xs hover:text-white dark:text-white'>Result: {resultValue} {unit}</div>
    </div>
  );
};

export default Converter;
