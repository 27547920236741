import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Header from './Header';
import UnixTime from './CurrentTime';
import UnixTimeSelect from './TimeSelect';
import MyComponent from './AddComponent';
import UnixToDateTimeForm from './GetTime';
import Converter from './EtherConverter'
import EthereumDonateButton from './Donate';
import './App.css';



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <>
      <Header />
      <UnixTime />
      <UnixTimeSelect />
      <MyComponent />
      <UnixToDateTimeForm />
      <Converter />
      <EthereumDonateButton />
    </>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
