import React, { useState } from 'react';
import moment from 'moment-timezone';

const UnixToDateTimeForm = () => {
  const [unix, setUnix] = useState("");
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [date, setDate] = useState("");

  const handleSubmit = (event) => {
    event.preventDefault();
    const date = moment.unix(unix).tz(timezone);
    setDate(date.format('MMM-DD-YYYY hh:mm A'));
  }

  const timezones = moment.tz.names();

  return (
    <>
      <div className='flex items-center justify-center'>
        <form onSubmit={handleSubmit} className='mt-12 mx-10 block lg:flex lg:space-x-6 xl:space-x-12 lg:items-center lg:justify-center"'>
        <label className='block hover:text-white dark:text-white'>
            Blocktime:{" "}
            <input type="text" value={unix} onChange={(e) => setUnix(e.target.value)} placeholder="Enter Blocktime" className='pl-2 relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'/>
        </label>
        <label className='block hover:text-white dark:text-white'>
            Timezone:{" "}
            <select value={timezone} onChange={(e) => setTimezone(e.target.value)} className='pl-2 relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'>
            {timezones.map(timezone => <option key={timezone} value={timezone}>{timezone}</option>)}
            </select>
        </label>
        <button type="submit" className='mt-5 lg:mt-0 block p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'>
          <span className="relative px-5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Submit
          </span>
        </button>
        </form>
        </div>
        <p className='flex justify-center text-xs hover:text-white dark:text-white'>{date}</p>
    </>
  );
}

export default UnixToDateTimeForm;
