import React from 'react';
import UnixTimeSelect from './TimeSelect';

class MyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
    };
    this.handleClick = this.handleClick.bind(this);
  }

  handleClick() {
    this.setState((prevState) => ({
      components: [...prevState.components, <UnixTimeSelect key={prevState.components.length} />],
    }));
  }

  render() {
    return (
      <>
        {this.state.components}
        <div className='flex mt-5 justify-center text-center hover:text-white dark:text-white'>
          <button onClick={this.handleClick} className='relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'>
            <span className="relative px-5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
              +
            </span>
          </button>
        </div>
      </>
    );
  }
}

export default MyComponent;