import React, { useState } from 'react';
import moment from 'moment-timezone';

function UnixTimeSelect() {
  const [date, setDate] = useState("");
  const [time, setTime] = useState("");
  const [timezone, setTimezone] = useState(moment.tz.guess());
  const [unixTime, setUnixTime] = useState("");


  const handleSubmit = (e) => {
    e.preventDefault();
    const dateTime = `${date} ${time}`;
    const futureTime = moment.tz(dateTime, timezone);
    setUnixTime({
      unix: futureTime.unix(),
      date: futureTime.format('MMM-DD-YYYY'),
      time: futureTime.format('hh:mm A'),
      timezone,
    });
  }

  const timezones = moment.tz.names();

  return (
    <>
    <div className='flex items-center justify-center'>
      <form onSubmit={handleSubmit} className="mb-5 lg:mb-0 m-2 mx-10 block lg:flex lg:space-x-6 xl:space-x-12 lg:items-center lg:justify-center">
        <label className='block hover:text-white dark:text-white'>
          Date:{" "}
          <input type="date" value={date} onChange={e => setDate(e.target.value)} className='pl-2 items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'/>
        </label>
        <label className='block hover:text-white dark:text-white'>
          Time:{" "}
          <input type="time" value={time} onChange={e => setTime(e.target.value)} className='pl-2 items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'/>
        </label>
        <label className='block  hover:text-white dark:text-white'>
          Timezone:{" "}
          <select value={timezone} onChange={e => setTimezone(e.target.value)} className='pl-2 items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white'>
            {timezones.map((zone, index) => (
              <option key={index} value={zone}>{zone}</option>
            ))}
          </select>
        </label>
        <button className='mt-5 lg:mt-0 block items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white' type="submit">
          <span className="px-5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Submit
          </span>
        </button>
      </form>
      </div>
      <div className='text-center'>
        {unixTime.unix && (
          <div className='flex items-center justify-center m-2 mb-8 hover:text-white dark:text-white text-center'>
              <ul className='flex items-center justify-center space-x-4 text-xs text-center'>
                  <li>Blocktime: {unixTime.unix}</li>
                  <li>Date: {unixTime.date}</li>
                  <li>Time: {unixTime.time}</li>
              </ul>
          </div>
        )}
      </div>
    </>
  );
}

export default UnixTimeSelect;