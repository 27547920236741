import React, { useState, useEffect } from 'react';

function UnixTime() {
  const [time, setTime] = useState(Math.floor(Date.now() / 1000));
  const [localTime, setLocalTime] = useState(new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric',  hour12: true }));
  const [date, setDate] = useState(new Date().toDateString());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(Math.floor(Date.now() / 1000));
      setLocalTime(new Date().toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', second: 'numeric', hour12: true }));
      setDate(new Date().toDateString());
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className='m-8'>
      <p className='flex justify-center m-4 text-2xl hover:text-white dark:text-white'>{time}</p>
      <div className='flex space-x-4 justify-center hover:text-white dark:text-white'>
        <p>{date}</p>
        <p>{localTime}</p>
      </div>
      <p className='flex justify-center text-xs hover:text-white dark:text-white'>Local Time and Date</p>
    </div>
  );
}

export default UnixTime;